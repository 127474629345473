import React, { useState, useEffect } from "react";
import Header from '../layouts/header/Header';
import Footer from '../layouts/footer/Footer';
import NewCardIssuanceTrend from "./new-card-issuance-trend/NewCardIssuanceTrend";
import { Button, Spinner } from "react-bootstrap";
import "./style.css";
import TopCardIssuersoftheMonth from "./top-card-issuers-of-the-month/TopCardIssuersoftheMonth";
import CardSpendsTrend from "./card-spends-trend/CardSpendsTrend";
import TopIssuerSpendsTrend from "./top-issuer-spends-trend/TopIssuerSpendsTrend";
import AverageTicketSize from "./average-ticket-size/AverageTicketSize";
import { useLocation, useParams  } from 'react-router-dom';
import ReactGA from 'react-ga4';
import { Helmet } from 'react-helmet';

const BankPaymentTrends = () => {

  const location = useLocation();
  const { cardName, bankName } = useParams();
  const [loading, setLoading] = useState(true);
  const [selectedCardType, setSelectedCardType] = useState();
  const [selectedBankType, setSelectedBankType] = useState();
  const [finalTitle, setFinalTitle] = useState('');
  const [mataDesc, setMataDesc] = useState('');

  useEffect(() => {
    const cardTypeFromQuery = cardName?.replace("card", "") || 'credit';
    const bankTypeFromQuery = cardTypeFromQuery === "credit" 
      ? bankName?.replace("-credit-card", "") || 'Industry'
      : bankName?.replace("-debit-card", "") || 'Industry';

    setSelectedCardType(cardTypeFromQuery);
    setSelectedBankType(bankTypeFromQuery);

    let title;
    let fullTitle;

    if (cardTypeFromQuery === "credit") {
      title = bankName?.replace(/-/g, ' ').toUpperCase();
      fullTitle = title?.replace("BANK CREDIT CARD", "Bank");
      setFinalTitle(`Detailed Credit Card Portfolio Insights for ${fullTitle}`);
      setMataDesc(`Track ${title}'s latest credit card portfolio trends with YieldLab.ai. Analyze spending patterns to stay ahead in the evolving payment space.`);
    } else {
      title = bankName?.replace(/-/g, ' ').toUpperCase();
      fullTitle = title?.replace("BANK DEBIT CARD", "Bank");
      setFinalTitle(`Comprehensive Debit Card Portfolio Insights for ${fullTitle}`);
      setMataDesc(`Follow ${title}'s latest debit card portfolio trends on YieldLab.ai. Gain insights into customer spending for a competitive advantage.`);
    }

    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname + window.location.search,
      title: bankTypeFromQuery + "_" + (cardTypeFromQuery === "credit" ? "CC" : "DC") + "_Details"
    });

    const timer = setTimeout(() => setLoading(false), 1000); // Adjust delay as needed

    return () => clearTimeout(timer);
  }, [cardName, bankName, location.search]);


  if (loading) {
    return (
      <div className="app">
        <Header />
        <div className="container mt-5 text-center">
          <Spinner animation="border" />
        </div>
        <Footer />
      </div>
    );
  }

  return (
    <div className="app">
      <Header />
      <Helmet>
        <meta name="description" content={mataDesc}/>
      </Helmet>
      <div className="container mt-5">
      <div className="d-flex title-container">
          <h1 className="page-title">{finalTitle}</h1>
      </div>
      </div>      
      <NewCardIssuanceTrend selectedCardType={selectedCardType} selectedBankType={selectedBankType}/>
      {/* <TopCardIssuersoftheMonth selectedCardType={selectedCardType} /> */}
      <CardSpendsTrend selectedCardType={selectedCardType} selectedBankType={selectedBankType}/>
      {/* <TopIssuerSpendsTrend selectedCardType={selectedCardType} /> */}
      <AverageTicketSize selectedCardType={selectedCardType} selectedBankType={selectedBankType}/>
      <Footer />
    </div>
  );
};

export default BankPaymentTrends;

import React from 'react'
import { Link } from 'react-router-dom'
import { Accordion } from 'react-bootstrap';

import "./style.css";
const Footer = () => {
  return (
    <div className="container text-bg-dark mt-7">
      <footer className="rounded p-6">
        <div className="row">
          <div className="col-lg-12 text-bg-light rounded p-4">
            <h4>About Us</h4>
            <p>YieldLab.ai is a platform for tracking and analysing the credit card market in India. We provide valuable insights into market share, trends, user spending patterns, and industry developments, helping businesses stay informed in the fast-evolving credit card landscape. In addition to market intelligence, we offer expert consulting services to help banks optimise their credit card portfolios. Whether you’re exploring credit card penetration or looking to drive higher engagement, YieldLab.ai combines data and expert analysis to empower your growth in the credit card market.
            </p>
          </div>
        </div>
        {/* <div className="row py-3 mtf-4 border-top">
          <div className="mt-4 pd-0">
            <Accordion defaultActiveKey="0">
              <Accordion.Item eventKey="0">
                <Accordion.Header><h4>Credit Card Trends</h4></Accordion.Header>
                <Accordion.Body>
                  <div className="row">
                    <div className="col-lg-3">
                        <div className="card-body">
                          <h5 className="card-title mb-3"><b>Private Sector Bank</b></h5>
                          <p className="card-text">
                            <Link className="nav-link" to="/payment-trends/credit/hdfc-bank-credit-card">HDFC Bank</Link>
                          </p>
                          <p className="card-text">
                            <Link className="nav-link" to="/payment-trends/credit/hdfc-bank-credit-card">ICICI Bank</Link>
                          </p>
                          <p className="card-text">
                            <Link className="nav-link" to="/payment-trends/credit/hdfc-bank-credit-card">Axis Bank</Link>
                          </p>
                          <p className="card-text">
                            <Link className="nav-link" to="/payment-trends/credit/hdfc-bank-credit-card">Kotak Mahindra Bank</Link>
                          </p>
                          <p className="card-text">
                            <Link className="nav-link" to="/payment-trends/credit/hdfc-bank-credit-card">RBL Bank</Link>
                          </p>
                          <p className="card-text">
                            <Link className="nav-link" to="/payment-trends/credit/hdfc-bank-credit-card">Induslnd  Bank</Link>
                          </p>
                          <p className="card-text">
                            <Link className="nav-link" to="/payment-trends/credit/hdfc-bank-credit-card">IDFC First Bank</Link>
                          </p>
                          <p className="card-text">
                            <Link className="nav-link" to="/payment-trends/credit/hdfc-bank-credit-card">Yes Bank</Link>
                          </p>
                          <p className="card-text">
                            <Link className="nav-link" to="/payment-trends/credit/hdfc-bank-credit-card">Federal Bank</Link>
                          </p>
                          <p className="card-text">
                            <Link className="nav-link" to="/payment-trends/credit/hdfc-bank-credit-card">South Indian Bank</Link>
                          </p>
                          <p className="card-text">
                            <Link className="nav-link" to="/payment-trends/credit/hdfc-bank-credit-card">Jammu & Kashmir Bank</Link>
                          </p>
                          <p className="card-text">
                            <Link className="nav-link" to="/payment-trends/credit/hdfc-bank-credit-card">CSB Bank</Link>
                          </p>
                          <p className="card-text">
                            <Link className="nav-link" to="/payment-trends/credit/hdfc-bank-credit-card">IDBI Bank</Link>
                          </p>
                          <p className="card-text">
                            <Link className="nav-link" to="/payment-trends/credit/hdfc-bank-credit-card">TamilNad Mercantile Bank</Link>
                          </p>
                          <p className="card-text">
                            <Link className="nav-link" to="/payment-trends/credit/hdfc-bank-credit-card">City Union Bank</Link>
                          </p>
                          <p className="card-text">
                            <Link className="nav-link" to="/payment-trends/credit/hdfc-bank-credit-card">Dhanalakshmi Bank</Link>
                          </p>
                          <p className="card-text">
                            <Link className="nav-link" to="/payment-trends/credit/hdfc-bank-credit-card">Karur Vsyaya Bank</Link>
                          </p>
                        </div>
                    </div>
                    <div className="col-lg-3">
                        <div className="card-body">
                          <h5 className="card-title mb-3"><b>Public Sector Bank</b></h5>
                          <p className="card-text">
                            <Link className="nav-link" to="/payment-trends/credit/hdfc-bank-credit-card">State Bank of India</Link>
                          </p>
                          <p className="card-text">
                            <Link className="nav-link" to="/payment-trends/credit/hdfc-bank-credit-card">Bank Of Baroda</Link>
                          </p>
                          <p className="card-text">
                            <Link className="nav-link" to="/payment-trends/credit/hdfc-bank-credit-card">Canara Bank</Link>
                          </p>
                          <p className="card-text">
                            <Link className="nav-link" to="/payment-trends/credit/hdfc-bank-credit-card">Union Bank of India</Link>
                          </p>
                          <p className="card-text">
                            <Link className="nav-link" to="/payment-trends/credit/hdfc-bank-credit-card">Punjab National Bank</Link>
                          </p>
                          <p className="card-text">
                            <Link className="nav-link" to="/payment-trends/credit/hdfc-bank-credit-card">Indian Bank</Link>
                          </p>
                          <p className="card-text">
                            <Link className="nav-link" to="/payment-trends/credit/hdfc-bank-credit-card">Indian Overseas Bank</Link>
                          </p>
                          <p className="card-text">
                            <Link className="nav-link" to="/payment-trends/credit/hdfc-bank-credit-card">Bank of India</Link>
                          </p>
                          <p className="card-text">
                            <Link className="nav-link" to="/payment-trends/credit/hdfc-bank-credit-card">Bank of Maharashtra</Link>
                          </p>
                        </div>
                    </div>
                    <div className="col-lg-3">
                        <div className="card-body">
                          <h5 className="card-title mb-3"><b>Foreign Bank</b></h5>
                          <p className="card-text">
                            <Link className="nav-link" to="/payment-trends/credit/hdfc-bank-credit-card">American Express</Link>
                          </p>
                          <p className="card-text">
                            <Link className="nav-link" to="/payment-trends/credit/hdfc-bank-credit-card">Standard Chartered Bank</Link>
                          </p>
                          <p className="card-text">
                            <Link className="nav-link" to="/payment-trends/credit/hdfc-bank-credit-card">HSBC</Link>
                          </p>
                          <p className="card-text">
                            <Link className="nav-link" to="/payment-trends/credit/hdfc-bank-credit-card">SBM Bank India</Link>
                          </p>
                          <p className="card-text">
                            <Link className="nav-link" to="/payment-trends/credit/hdfc-bank-credit-card">DBS Bank</Link>
                          </p>
                          <p className="card-text">
                            <Link className="nav-link" to="/payment-trends/credit/hdfc-bank-credit-card">Citi Bank</Link>
                          </p>
                        </div>
                    </div>
                    <div className="col-lg-3">
                        <div className="card-body">
                          <h5 className="card-title mb-3"><b>Small Finance Banks</b></h5>
                          <p className="card-text">
                            <Link className="nav-link" to="/payment-trends/credit/hdfc-bank-credit-card">AU SMALL FINANCE BANK</Link>
                          </p>
                          <p className="card-text">
                            <Link className="nav-link" to="/payment-trends/credit/hdfc-bank-credit-card">Utkarsh Small Finance Bank</Link>
                          </p>
                        </div>
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="1">
                <Accordion.Header><h4>Debit Card Trends</h4></Accordion.Header>
                <Accordion.Body>
                  <div className="row">
                    <div className="col-lg-3 col-lg-3-custom">
                        <div className="card-body">
                          <h5 className="card-title mb-3"><b>Private Sector Bank</b></h5>
                          <p className="card-text">
                            <Link className="nav-link" to="/payment-trends/credit/hdfc-bank-credit-card">Axis Bank</Link>
                          </p>
                          <p className="card-text">
                            <Link className="nav-link" to="/payment-trends/credit/hdfc-bank-credit-card">Bandhan Bank</Link>
                          </p>
                          <p className="card-text">
                            <Link className="nav-link" to="/payment-trends/credit/hdfc-bank-credit-card">City Union Bank</Link>
                          </p>
                          <p className="card-text">
                            <Link className="nav-link" to="/payment-trends/credit/hdfc-bank-credit-card">CSB Bank</Link>
                          </p>
                          <p className="card-text">
                            <Link className="nav-link" to="/payment-trends/credit/hdfc-bank-credit-card">DCB Bank</Link>
                          </p>
                          <p className="card-text">
                            <Link className="nav-link" to="/payment-trends/credit/hdfc-bank-credit-card">Dhanalakshmi Bank</Link>
                          </p>
                          <p className="card-text">
                            <Link className="nav-link" to="/payment-trends/credit/hdfc-bank-credit-card">Federal Bank</Link>
                          </p>
                          <p className="card-text">
                            <Link className="nav-link" to="/payment-trends/credit/hdfc-bank-credit-card">HDFC Bank</Link>
                          </p>
                          <p className="card-text">
                            <Link className="nav-link" to="/payment-trends/credit/hdfc-bank-credit-card">ICICI Bank</Link>
                          </p>
                          <p className="card-text">
                            <Link className="nav-link" to="/payment-trends/credit/hdfc-bank-credit-card">IDBI Bank</Link>
                          </p>
                          <p className="card-text">
                            <Link className="nav-link" to="/payment-trends/credit/hdfc-bank-credit-card">IDFC First Bank</Link>
                          </p>
                          <p className="card-text">
                            <Link className="nav-link" to="/payment-trends/credit/hdfc-bank-credit-card">Induslnd  Bank</Link>
                          </p>
                          <p className="card-text">
                            <Link className="nav-link" to="/payment-trends/credit/hdfc-bank-credit-card">Jammu & Kashmir Bank</Link>
                          </p>
                          <p className="card-text">
                            <Link className="nav-link" to="/payment-trends/credit/hdfc-bank-credit-card">Karnataka Bank</Link>
                          </p>
                          <p className="card-text">
                            <Link className="nav-link" to="/payment-trends/credit/hdfc-bank-credit-card">Karur Vsyaya Bank</Link>
                          </p>
                          <p className="card-text">
                            <Link className="nav-link" to="/payment-trends/credit/hdfc-bank-credit-card">Kotak Mahindra Bank</Link>
                          </p>
                          <p className="card-text">
                            <Link className="nav-link" to="/payment-trends/credit/hdfc-bank-credit-card">Nainital Bank</Link>
                          </p>
                          <p className="card-text">
                            <Link className="nav-link" to="/payment-trends/credit/hdfc-bank-credit-card">RBL Bank</Link>
                          </p>
                          <p className="card-text">
                            <Link className="nav-link" to="/payment-trends/credit/hdfc-bank-credit-card">South Indian Bank</Link>
                          </p>
                          <p className="card-text">
                            <Link className="nav-link" to="/payment-trends/credit/hdfc-bank-credit-card">TamilNad Mercantile Bank</Link>
                          </p>
                          <p className="card-text">
                            <Link className="nav-link" to="/payment-trends/credit/hdfc-bank-credit-card">Yes Bank</Link>
                          </p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-lg-3-custom">
                        <div className="card-body">
                          <h5 className="card-title mb-3"><b>Public Sector Bank</b></h5>
                          <p className="card-text">
                            <Link className="nav-link" to="/payment-trends/credit/hdfc-bank-credit-card">Public Sector Bank</Link>
                          </p>
                          <p className="card-text">
                            <Link className="nav-link" to="/payment-trends/credit/hdfc-bank-credit-card">Bank Of Baroda</Link>
                          </p>
                          <p className="card-text">
                            <Link className="nav-link" to="/payment-trends/credit/hdfc-bank-credit-card">Bank of India</Link>
                          </p>
                          <p className="card-text">
                            <Link className="nav-link" to="/payment-trends/credit/hdfc-bank-credit-card">Bank of Maharashtra</Link>
                          </p>
                          <p className="card-text">
                            <Link className="nav-link" to="/payment-trends/credit/hdfc-bank-credit-card">Central Bank of India</Link>
                          </p>
                          <p className="card-text">
                            <Link className="nav-link" to="/payment-trends/credit/hdfc-bank-credit-card">Indian Bank</Link>
                          </p>
                          <p className="card-text">
                            <Link className="nav-link" to="/payment-trends/credit/hdfc-bank-credit-card">Canara Bank</Link>
                          </p>
                          <p className="card-text">
                            <Link className="nav-link" to="/payment-trends/credit/hdfc-bank-credit-card">Indian Overseas Bank</Link>
                          </p>
                          <p className="card-text">
                            <Link className="nav-link" to="/payment-trends/credit/hdfc-bank-credit-card">Punjab and Sind Bank</Link>
                          </p>
                          <p className="card-text">
                            <Link className="nav-link" to="/payment-trends/credit/hdfc-bank-credit-card">Punjab National Bank</Link>
                          </p>
                          <p className="card-text">
                            <Link className="nav-link" to="/payment-trends/credit/hdfc-bank-credit-card">State Bank of India</Link>
                          </p>
                          <p className="card-text">
                            <Link className="nav-link" to="/payment-trends/credit/hdfc-bank-credit-card">Uco Bank</Link>
                          </p>
                          <p className="card-text">
                            <Link className="nav-link" to="/payment-trends/credit/hdfc-bank-credit-card">Union Bank of India</Link>
                          </p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-lg-3-custom">
                        <div className="card-body">
                          <h5 className="card-title mb-3"><b>Foreign Bank</b></h5>
                          <p className="card-text">
                            <Link className="nav-link" to="/payment-trends/credit/hdfc-bank-credit-card">Bank of Bahrain & Kuwait</Link>
                          </p>
                          <p className="card-text">
                            <Link className="nav-link" to="/payment-trends/credit/hdfc-bank-credit-card">BSC</Link>
                          </p>
                          <p className="card-text">
                            <Link className="nav-link" to="/payment-trends/credit/hdfc-bank-credit-card">Barclays Bank</Link>
                          </p>
                          <p className="card-text">
                            <Link className="nav-link" to="/payment-trends/credit/hdfc-bank-credit-card">DBS Bank</Link>
                          </p>
                          <p className="card-text">
                            <Link className="nav-link" to="/payment-trends/credit/hdfc-bank-credit-card">Deutsche Bank</Link>
                          </p>
                          <p className="card-text">
                            <Link className="nav-link" to="/payment-trends/credit/hdfc-bank-credit-card">Doha Bank QPSC</Link>
                          </p>
                          <p className="card-text">
                            <Link className="nav-link" to="/payment-trends/credit/hdfc-bank-credit-card">HSBC Bank</Link>
                          </p>
                          <p className="card-text">
                            <Link className="nav-link" to="/payment-trends/credit/hdfc-bank-credit-card">KEB HANA Bank</Link>
                          </p>
                          <p className="card-text">
                            <Link className="nav-link" to="/payment-trends/credit/hdfc-bank-credit-card">Kookmin Bank</Link>
                          </p>
                          <p className="card-text">
                            <Link className="nav-link" to="/payment-trends/credit/hdfc-bank-credit-card">SBM Bank India</Link>
                          </p>
                          <p className="card-text">
                            <Link className="nav-link" to="/payment-trends/credit/hdfc-bank-credit-card">Standard Chartered Bank</Link>
                          </p>
                          <p className="card-text">
                            <Link className="nav-link" to="/payment-trends/credit/hdfc-bank-credit-card">Woori Bank</Link>
                          </p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-lg-3-custom">
                        <div className="card-body">
                          <h5 className="card-title mb-3"><b>Small Finance Banks</b></h5>
                          <p className="card-text">
                            <Link className="nav-link" to="/payment-trends/credit/hdfc-bank-credit-card">AU Small Finance Bank</Link>
                          </p>
                          <p className="card-text">
                            <Link className="nav-link" to="/payment-trends/credit/hdfc-bank-credit-card">Capital Small Finance Bank</Link>
                          </p>
                          <p className="card-text">
                            <Link className="nav-link" to="/payment-trends/credit/hdfc-bank-credit-card">Equitas Small Finance Bank</Link>
                          </p>
                          <p className="card-text">
                            <Link className="nav-link" to="/payment-trends/credit/hdfc-bank-credit-card">ESAF Small Finance Bank</Link>
                          </p>
                          <p className="card-text">
                            <Link className="nav-link" to="/payment-trends/credit/hdfc-bank-credit-card">Jana Small Finance Bank</Link>
                          </p>
                          <p className="card-text">
                            <Link className="nav-link" to="/payment-trends/credit/hdfc-bank-credit-card">Northeast Small Finance Bank</Link>
                          </p>
                          <p className="card-text">
                            <Link className="nav-link" to="/payment-trends/credit/hdfc-bank-credit-card">Shivalik Small Finance Bank</Link>
                          </p>
                          <p className="card-text">
                            <Link className="nav-link" to="/payment-trends/credit/hdfc-bank-credit-card">Suryoday Small Finance Bank</Link>
                          </p>
                          <p className="card-text">
                            <Link className="nav-link" to="/payment-trends/credit/hdfc-bank-credit-card">Ujjivan Small Finance Bank</Link>
                          </p>
                          <p className="card-text">
                            <Link className="nav-link" to="/payment-trends/credit/hdfc-bank-credit-card">Unity Small Finance Bank</Link>
                          </p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-lg-3-custom">
                        <div className="card-body">
                          <h5 className="card-title mb-3"><b>Payments Bank</b></h5>
                          <p className="card-text">
                            <Link className="nav-link" to="/payment-trends/credit/hdfc-bank-credit-card">Airtel Payments Bank</Link>
                          </p>
                          <p className="card-text">
                            <Link className="nav-link" to="/payment-trends/credit/hdfc-bank-credit-card">Fino Payments Bank</Link>
                          </p>
                          <p className="card-text">
                            <Link className="nav-link" to="/payment-trends/credit/hdfc-bank-credit-card">India Post Payments Bank</Link>
                          </p>
                          <p className="card-text">
                            <Link className="nav-link" to="/payment-trends/credit/hdfc-bank-credit-card">Jio Payments Bank</Link>
                          </p>
                          <p className="card-text">
                            <Link className="nav-link" to="/payment-trends/credit/hdfc-bank-credit-card">NSDL Payments Bank</Link>
                          </p>
                          <p className="card-text">
                            <Link className="nav-link" to="/payment-trends/credit/hdfc-bank-credit-card">Paytm Payments Bank</Link>
                          </p>
                        </div>
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>


              <Accordion.Item eventKey="2">
                <Accordion.Header><h4>Services</h4> <span><Link to="/#" className="btn btn-primary up-btn round">Upcoming</Link></span></Accordion.Header>
                <Accordion.Body>
                  <div className="row">
                    <div className="col-lg-3">
                        <div className="card-body">
                          <h5 className="card-title mb-3"><b>Credit Card Consulting </b></h5>
                        </div>
                    </div>
                    <div className="col-lg-3">
                        <div className="card-body">
                          <h5 className="card-title mb-3"><b>Debit Card Consulting </b></h5>
                        </div>
                    </div>
                    <div className="col-lg-3">
                        <div className="card-body">
                          <h5 className="card-title mb-3"><b>Learn Credit Card Yield Management </b></h5>
                        </div>
                    </div>
                    <div className="col-lg-3">
                        <div className="card-body">
                          <h5 className="card-title mb-3"><b>AI Solutions for Credit And Debit Card Portfolio Management</b></h5>
                          
                        </div>
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="3">
                <Accordion.Header><h4>Blog</h4></Accordion.Header>
                <Accordion.Body>
                  <div className="row">
                    <div className="col-lg-3">
                        <div className="card-body">
                          <h5 className="card-title mb-3"><b>Learn with YieldLab</b></h5>
                        </div>
                    </div>
                    <div className="col-lg-3">
                        <div className="card-body">
                          <h5 className="card-title mb-3"><b>LaunchPad</b></h5>
                        </div>
                    </div>
                    <div className="col-lg-3">
                        <div className="card-body">
                          <h5 className="card-title mb-3"><b>Reports</b></h5>
                        </div>
                    </div>
                    <div className="col-lg-3">
                        <div className="card-body">
                          <h5 className="card-title mb-3"><b><Link className="nav-link" to="/payment-trends/credit/hdfc-bank-credit-card">Payment Bulletin</Link></b></h5>
                          
                        </div>
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>

        </div> */}
       
        <div className="row py-3 my-4 border-top text-center ">
          <div className="row mt-4">
            <div className="col-lg-6 col-md-8">
              <div>
                <Link to="/home" className="d-flex align-items-center mb-3 mb-md-0 me-md-auto text-dark text-decoration-none">
                  <span className="fs-4"><img className="logo" src="https://yieldlab.ai/asset/images/YieldLab-Logo-svg.png" alt="Yield Lab" /></span>
                </Link>
              </div>
              <div className="mt-3 align-left">
                <span className="p-1">Stay on Top of Payment Trends</span>
              </div>
            </div>
            <div className="col-lg-6 col-md-4 align-end">
              <div>
                Contact Us
              </div>
              <div className="pt-1">
                <div className="p-1" role="button">
                <p className="mail-txt"><a href="mailto:research@yieldlab.ai" target="_blank">research@yieldlab.ai</a></p> 
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 mt-5">
            <div className="mt-1">
                <span className="p-1" role="button"><i className="fa-brands fa-facebook"></i></span>
                <span className="p-1" role="button"><i className="fa-brands fa-square-instagram"></i></span>
                <span className="p-1" role="button"><i className="fa-brands fa-twitter"></i></span>
            </div>
            <span className="mt-1">© 2024 Yield Lab. All rights reserved.</span>
          </div>
        </div>
      </footer>
    </div>
  )
}

export default Footer
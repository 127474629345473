import React, { useState, useEffect } from "react";
import { Form, Alert, Button, Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useUserAuth } from "../../context/UserAuthContext";
import Header from '../layouts/header/Header';
import Footer from '../layouts/footer/Footer';
import { sendSignInLinkToEmail } from 'firebase/auth';
import { auth, firestore } from "../../firebase";
import "./style.css";
import { Link } from 'react-router-dom';
import ReactGA from 'react-ga4';
const Login = () => {
  ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search, title: "Login" });
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [error, setError] = useState("");
  const { googleSignIn, user } = useUserAuth();
  const navigate = useNavigate();
  const [infoMessage, setInfoMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    const storedEmail = localStorage.getItem('email');
    if (storedEmail) {
      setEmail(storedEmail);
    }
  }, []); // Empty dependency array ensures this runs once on mount

  useEffect(() => {
    if (user) {
      navigate('/payment-trends');
    }
  }, [user, navigate]);

  // Function to validate form fields
  const validateForm = () => {
    let isValid = true;

    // Validate email format
    if (!validateEmail(email)) {
      setError('Please enter a valid email address.');
      isValid = false;
    }

    // Validate privacy policy checkbox
    if (!document.getElementById('privacy-policy').checked) {
      setError('Please agree to the privacy policy.');
      isValid = false;
    }

    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Perform client-side validation
    if (!validateForm()) {
      return;
    }

    setIsLoading(true); // Start loading state
    setError(""); // Clear any previous errors

    try {
      // Send sign-in link to email
      await sendSignInLinkToEmail(auth, email, {
        url: 'https://yieldlab.ai/home',
        handleCodeInApp: true,
      });

      // Save user data to Firestore after sending sign-in link
      const userRef = firestore.collection('users').doc(email);
      await userRef.set({
        firstName,
        lastName,
        mobileNumber,
        email,
      });

      localStorage.setItem('email', email);
      setInfoMessage('We have sent you an email with a link to sign in');
    } catch (error) {
      if (error.code === 'auth/quota-exceeded') {
        setErrorMessage('Oops! We have exceeded our daily limit for sending sign-in links. Please try again later.');
      } else {
        setErrorMessage(`Error sending sign-in link: ${error.message}`);
      }
      console.error("Error sending sign-in link:", error);
    } finally {
      setIsLoading(false); // Stop loading state
    }

    navigate("/signin"); // Navigate to signup page after processing
  };

  const handleGoogleSignIn = async (e) => {
    e.preventDefault();
    try {
      await googleSignIn();
      navigate("/home");
    } catch (error) {
      console.error("Error signing in with Google:", error);
      setError(error.message); // Set error message for Google sign-in errors
    }
  };

  // Function to validate email format
  const validateEmail = (email) => {
    // Regular expression for email validation
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  return (
    <div className="app">
      <Header />
      <div className="container mt-5">
        <div className="row justify-content-center">
          <div className="card w-50-sec shadow mb-5 bg-body rounded">
            <div className="card-body p-5">
              <div className="row">
                <h4 className="card-title fw-bold">Sign in <Link to="/signup" className="btn btn-primary" style={{ float: 'inline-end' }}>Don’t have an account</Link></h4>
              </div>
              <div className="form mt-4 text-center">
                {isLoading && ( // Render spinner if isLoading is true
                  <div className="text-center mt-3">
                    <Spinner animation="border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </Spinner>
                  </div>
                )}
                {error && <Alert variant="danger">{error}</Alert>}
                {errorMessage && <div className='error-msg'>{errorMessage}</div>}
                {infoMessage && <div className='info-msg'>{infoMessage}</div>}
                <div className="mt-5 d-grid gap-2">
                  <Button className="btn google-signin p-3" onClick={handleGoogleSignIn}>
                    <span className="p-2"><i className="fa-brands fa-google"></i></span>Sign in with Google
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Login;

import React, { useState, useEffect } from 'react';
import  Footer  from "../layouts/footer/Footer";
import Header from '../layouts/header/Header';
import ReactGA from 'react-ga4';
import { Helmet } from 'react-helmet';
const AboutUs = () => {
  
  ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search, title: "About_Us" });
  return (
    <div className="app">
      <Header />
      <Helmet>
        <meta name="description" content="Gain insights into India's credit card market with YieldLab.ai. Track market share, spending trends, and industry developments for a competitive edge" />
      </Helmet>
        <div className="container mt-5 m-container credit-debit-bg">
          <div className="row justify-content-between">
            <h1 className="page-title">YieldLab.ai | Credit & Debit Card Analytics</h1>
            <div className="col-lg-12 d-block order-lg-1">
              <p>YieldLab.ai is a leading platform for tracking and analyzing the credit card market in India. We provide insights into credit card market share for banks in India, future of credit card trends, user spending patterns, and industry developments. Whether you're exploring credit card penetration or studying the evolving credit card industry, YieldLab.ai offers comprehensive data and expert analysis to keep you informed. Stay ahead in the credit card market with our latest blogs and updates.
              </p>
            </div>
          </div>
        </div>
      <Footer />
    </div>
  );
};

export default AboutUs;
import { createContext, useContext, useEffect, useState } from "react";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  onAuthStateChanged,
  signOut,
  GoogleAuthProvider,
  signInWithPopup,
  isSignInWithEmailLink,
  signInWithEmailLink,
} from "firebase/auth";
import { auth } from "../firebase";
import { useNavigate } from 'react-router-dom';
import { Spinner } from 'react-bootstrap'; // Import Spinner from react-bootstrap or your preferred library

const userAuthContext = createContext();

export function UserAuthContextProvider({ children }) {
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [infoMessage, setInfoMessage] = useState('');
  let navigate = useNavigate();

  // Function to handle email link authentication
  const authenticateUser = async () => {
    if (user) {
      navigate('/home');
      return;
    }
    if (isSignInWithEmailLink(auth, window.location.href)) {
      let emailFromStorage = localStorage.getItem('email');
      if (!emailFromStorage) {
        emailFromStorage = window.prompt('Please provide your email');
      }
      setIsLoading(true);
      try {
        await signInWithEmailLink(auth, emailFromStorage, window.location.href);
        localStorage.removeItem('email');
        navigate('/home'); // Redirect to home after successful sign-in
      } catch (error) {
        setErrorMessage(error.message);
        navigate('/signin'); // Redirect to signup page if sign-in fails
      } finally {
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      console.log("Auth", currentUser); // Corrected to use 'currentUser' instead of 'currentuser'
      setUser(currentUser);
    });

    // Check for email link sign-in when component mounts
    authenticateUser();

    return () => {
      unsubscribe();
    };
  }, []);

  function logIn(email, password) {
    setIsLoading(true);
    return signInWithEmailAndPassword(auth, email, password)
      .finally(() => setIsLoading(false));
  }

  function signUp(email, password) {
    setIsLoading(true);
    return createUserWithEmailAndPassword(auth, email, password)
      .finally(() => setIsLoading(false));
  }

  function logOut() {
    setIsLoading(true);
    return signOut(auth)
      .finally(() => setIsLoading(false));
  }

  function googleSignIn() {
    setIsLoading(true);
    const googleAuthProvider = new GoogleAuthProvider();
    return signInWithPopup(auth, googleAuthProvider)
      .finally(() => setIsLoading(false));
  }

  return (
    <userAuthContext.Provider
      value={{ user, logIn, signUp, logOut, googleSignIn, authenticateUser }}
    >
      {isLoading && (
        <div className="d-flex justify-content-center align-items-center" style={{ minHeight: '100vh' }}>
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      )}
      {!isLoading && children}
    </userAuthContext.Provider>
  );
}

export function useUserAuth() {
  return useContext(userAuthContext);
}

// Import the functions you need from the SDKs you need
// import firebase from 'firebase/compat/app';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
// import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyA9CE01SL5bHFrOyZmRbHuYtra6loJZLRk",
  authDomain: "yield-lab.firebaseapp.com",
  projectId: "yield-lab",
  storageBucket: "yield-lab.appspot.com",
  messagingSenderId: "384241996501",
  appId: "1:384241996501:web:4538d14fd4b6f75ae0c85c",
  measurementId: "G-XQKTQ7FLGZ"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

export const auth = firebase.auth();
export const firestore = firebase.firestore();

export default firebase;
// const analytics = getAnalytics(app);
import React, { useState, useEffect } from "react";
import Header from '../layouts/header/Header';
import Footer from '../layouts/footer/Footer';
import NewCardIssuanceTrend from "./new-card-issuance-trend/NewCardIssuanceTrend";
import { Button, Spinner } from "react-bootstrap";
import "./style.css";
import TopCardIssuersoftheMonth from "./top-card-issuers-of-the-month/TopCardIssuersoftheMonth";
import CardSpendsTrend from "./card-spends-trend/CardSpendsTrend";
import TopIssuerSpendsTrend from "./top-issuer-spends-trend/TopIssuerSpendsTrend";
import AverageTicketSize from "./average-ticket-size/AverageTicketSize";
import { useLocation, useParams } from 'react-router-dom';
import ReactGA from 'react-ga4';
import { Helmet } from 'react-helmet';
const PaymentTrends = () => {
 
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [selectedCardType, setSelectedCardType] = useState();
  const { cardName } = useParams();
  useEffect(() => {
    
    const cardTypeFromQuery = cardName?.replace("-cards", "") || 'credit'; // Default to 'credit' if not found
    if(cardName){
      if(cardTypeFromQuery === 'credit'){
        ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search, title: "Trends_CC" });
      } else {
        ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search, title: "Trends_DC" });
      }
    } else {
      ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search, title: "Payment_Trends_Defaut" });
    }
    // Simulate a loading period or perform async operation if needed
    const timer = setTimeout(() => {
      setSelectedCardType(cardTypeFromQuery);
      ReactGA.event({
        category: cardTypeFromQuery,
        action: 'Click',
        label: 'Card Type',
      });
      setLoading(false);
    }, 500); // Adjust delay as needed

    return () => clearTimeout(timer);
  }, [cardName]);

  const handleCardTypeClick = (cardType) => {
    setSelectedCardType(cardType);
    ReactGA.event({
      category: cardType,
      action: 'Click',
      label: 'Card Type',
    });
  };

  if (loading) {
    return (
      <div className="app">
        <Header />
        <div className="container mt-5 text-center">
          <Spinner animation="border" />
        </div>
        <Footer />
      </div>
    );
  }

  return (
    <div className="app">
      <Header />
      <Helmet>
        <meta name="description" content="Monitor credit and debit card trends with YieldLab.ai. Stay ahead of the payment landscape with data on issuers, customer spending, and behavior" />
      </Helmet>
      <div className="container mt-5">
        <div className="d-flex title-container">
          <h1 className="page-title">Expert Credit & Debit Card Analytics for India's Financial Market</h1>
        </div>
      </div> 
      <div className="container mt-5">
        <div className="d-flex justify-content-center align-items-center text-center">
          <span>
            <Button
              variant="outline-primary"
              className={`btn-light p-2 credit-card-btn ${selectedCardType === 'credit' ? 'active' : ''}`}
              onClick={() => handleCardTypeClick('credit')}
            >
              Credit Cards
            </Button>
          </span>
          <span>
            <Button
              variant="outline-primary"
              className={`btn-light p-2 debit-card-btn ${selectedCardType === 'debit' ? 'active' : ''}`}
              onClick={() => handleCardTypeClick('debit')}
            >
              Debit Cards
            </Button>
          </span>
        </div>
      </div>
      <NewCardIssuanceTrend selectedCardType={selectedCardType} />
      <TopCardIssuersoftheMonth selectedCardType={selectedCardType} />
      <CardSpendsTrend selectedCardType={selectedCardType} />
      <TopIssuerSpendsTrend selectedCardType={selectedCardType} />
      <AverageTicketSize selectedCardType={selectedCardType} />
      <Footer />
    </div>
  );
};

export default PaymentTrends;

import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router";
import { useUserAuth } from "../../context/UserAuthContext";
import  Footer  from "../layouts/footer/Footer";
import Header from '../layouts/header/Header';
import { Form, Alert, Dropdown, Modal, Button, Spinner, Row, Col  } from "react-bootstrap";
import { Link } from 'react-router-dom';
import "./style.css";
import { FacebookShare, TwitterShare, LinkedinShare, WhatsappShare, EmailShare   } from 'react-share-kit';
import axios from 'axios';
import ReactGA from 'react-ga4';
import { Helmet } from 'react-helmet';
const Home = () => {
  
  ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search, title: "Home" });
  localStorage.setItem('email', '');
  const [email, setEmail] = useState("");
  const [data, setData] = useState([]);
  const [loadingApi, setLoading] = useState(false);
  const [errorApi, setError] = useState(null);

  const { logOut, user, loading, error } = useUserAuth(); // Ensure useUserAuth returns these properties
  const navigate = useNavigate();
  const shareUrl = 'https://yieldlab.ai';
  const titleToShare = 'Hey, I just found YieldLab.ai which helps me stay updated with the latest payment trends and Insights in India. Try it now: ';

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError(null);
      try {
        const response = await fetch('https://yieldlab.ai:9000/api/getTrendsFortheMonth');
        if (!response.ok) {
          throw new Error('Network response was not ok.');
        }
        const result = await response.json();
        setData(result);
      } catch (error) {
        console.error('Error fetching data:', error);
        setError('Error fetching data.');
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []); // Empty dependency array ensures this runs once on mount

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubscribe = () => {
    // Handle subscribe logic here
    localStorage.setItem('email', email);
    console.log('Subscribing with email:', email);
    navigate('/signin');
  };

  const [showModal, setShowModal] = useState(false);

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  const handleChange = (id, field, value) => {
    setData(prevData =>
      prevData.map(item =>
        item.id === id ? { ...item, [field]: value } : item
      )
    );
  };

  const handleSave = async () => {
    setData([...data]);
    handleCloseModal();
    console.log(data);
    try {
      await axios.put(`https://yieldlab.ai:9000/api/updateTrendsFortheMonth`, data);

    } catch (error) {
      console.error('Error updating data:', error);
    }
  };
  

  if (loading) {
    return <div>Loading...</div>;
  }
  if (error) {
    return <div>Error: {error.message}</div>;
  }

  const handleClickShare = () => {
    ReactGA.event({
        category: 'Social Links Share',
        action: 'Click',
        label: 'Social Links Share',
    });
};


  return (
    <div className="app">
      <Header />
        <Helmet>
          <meta name="description" content="Explore YieldLab's expert insights on credit and debit card portfolios in India. Optimize your portfolio with yield management strategies and performance comparisons" />
        </Helmet>
        <div className="container mt-1 m-container">
          <div className="row justify-content-between">
            <div className="col-lg-6">
              <div className="mt-8">
                <h1 className="info-text">Stay Updated with the Latest <span className="bg-color">Payment </span> Trends and Insights</h1>
                <div className="mt-3">
                  <p>Get alerts on latest spend reports & insights</p>
                </div>
                <div className="mt-3">
                  {user ? (
                    // <Link className="btn btn-dark p-3">Share <i className="fa-solid fa-share-nodes"></i></Link>
                      <Dropdown onClick={() => handleClickShare()}>
                      <Dropdown.Toggle variant="btn btn-dark p-3" id="dropdown-basic" >
                      Share <i className="fa-solid fa-share-nodes"></i>
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item>
                          <FacebookShare url={shareUrl} quote={titleToShare} round='true' size='40'/>
                          <TwitterShare
                            url={shareUrl} title={titleToShare} round='true' size='40'
                          />
                          <LinkedinShare 
                            url={shareUrl} quote={titleToShare} round='true' size='40'
                          />
                          <WhatsappShare  
                            url={shareUrl} title={titleToShare} round='true' size='40'
                          />
                          <EmailShare  
                            url={shareUrl} body={titleToShare} round='true' size='40'
                          />
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  ) : (
                    <Link to="/signin" className="btn btn-dark p-3">Subscribe</Link>
                  )}
                </div>
                
                <div className="mt-2">
                  <p><i className="fa-regular fa-hand-peace"></i> We promise not to spam!</p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 d-flex align-items-center justify-content-center text-center background-image">
              <Link className="nav-link" to="/payment-trends">
                <img src="asset/images/credit-card-debit-card-usage-trends-2024.svg" alt="Chart showing the rise in credit card usage trends in 2024" className="img-fluid" />
              </Link>
            </div>
          </div>
        </div>
        

        <div className="container mt-7 m-container">
          <div className="row pt-4">
            <div className="col-12 col-lg-8 d-flex">
              <h4>Trends for the Month</h4>
            </div>
            <div className="col-12 col-lg-4 d-flex align-items-center justify-content-lg-end">
              <div className="d-flex flex-wrap gap-2">
                <div>
                  <Button className="btn btn-outline-primary btn-light rounded btn-sm">
                    <i className="fa-regular fa-calendar-days mr-2"></i> 
                    {data.length > 2 && data[2] && (
                      <React.Fragment>{data[2].name}</React.Fragment>
                    )}
                  </Button>
                  { user && (user.email === 'mail2abhishekh@gmail.com' || user.email === 'singh.gyan@gmail.com' || user.email === 'nisupatel8022@gmail.com' ) && (
                  <Button className="btn btn-outline-primary btn-light btn-sm" onClick={handleShowModal} >
                    <i className="fa-solid fa-edit"></i>
                  </Button>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-4">
            {loadingApi && <div className="text-center"><Spinner animation="border" /></div>}
            {errorApi && <div className="text-center"><Alert variant="danger">{error}</Alert></div>}
            {data.length > 0 ? (
              data.map((trends, index) => (
                <React.Fragment key={trends.id}>
                  {(trends.id === 1 || trends.id === 2) && (
                      <div className="col-lg-4 p-2" >
                        <Link className="nav-link" to={`/payment-trends/${trends?.url}`}>
                          <div className={`card top-sec hm-bg-color-${index + 1}`}>
                            <div className="m-3">
                              <span className="mr-2"><small>{trends?.name}</small></span>
                              <span><small className="up-down-btn">
                                {trends?.change > 0 ? (
                                  <b>
                                    <i className="fa-solid fa-arrow-up"></i> {trends?.change}%
                                  </b>
                                ) : (
                                  <b>
                                    <i className="fa-solid fa-arrow-down"></i> {trends?.change}%
                                  </b>
                                )}
                                </small>
                              </span>
                              <h6><b>{trends?.value}</b></h6>
                            </div>
                          </div>
                        </Link>
                      </div>
                  )}
                  { index === 1 && (
                    <div className="col-lg-4 p-2">
                      <Link className="nav-link" to="/payment-trends">
                        <div className='card top-sec hm-bg-color-3'>
                          <div className="mar-2">
                            <h6 className='m-0'><b>View More</b></h6>
                          </div>
                        </div>
                      </Link>
                    </div>
                  )}
                </React.Fragment>
              ))
            ) : (
              <div className="text-center no-data-container">
                <div className="mixed-chart text-center p-3">
                  <img src="https://yieldlab.ai/asset/images/no-data.svg" alt="no-data" className="img-fluid" />
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="container mt-7 m-container credit-debit-bg">
          <div className="row justify-content-between">
            <div className="col-lg-6 d-flex align-items-center order-lg-2">
              <div className="ml-3">
                <h1 className="fs-1 fw-bold">
                  <Link className="nav-link" to="/payment-trends/credit-cards">Credit Card Trends</Link>
                </h1>
                <div className="d-flex">
                  <h6>Stay on top of latest credit card issuance and spends trends</h6>
                  {/* <h6>Stay Updated with the Latest Credit Card Issuance and Spends Trends</h6> */}
                </div>
              </div>
            </div>
            <div className="col-lg-6 d-flex align-items-center justify-content-center text-center background-image order-lg-1">
            <Link className="nav-link" to="/payment-trends/credit-cards">
              <img src="asset/images/best-credit-cards-india-trends-2024.svg" alt="Chart showing the best credit cards usage in India in 2024" className="img-fluid" />
            </Link>
            </div>
          </div>

          <div className="row mt-5">
            <h4 className="title">Track Credit Card Trends for Your Bank</h4>
            <div className="col-12 d-flex flex-column mt-4">
              <ul className="list bank-list">
                <li className="list-item bank-list-item">
                  <Link className="nav-link bank-nav-link" to="/payment-trends/credit/hdfc-bank-credit-card">
                    HDFC Bank Credit Card 
                  </Link>
                </li>
                <li className="list-item">
                  <Link className="nav-link bank-nav-link" to="/payment-trends/credit/icici-bank-credit-card">
                    ICICI Bank Credit Card 
                  </Link>
                </li>
                <li className="list-item">
                  <Link className="nav-link bank-nav-link" to="/payment-trends/credit/axis-bank-credit-card">
                    Axis Bank Credit Card  
                  </Link>
                </li>
                <li className="list-item">
                  <Link className="nav-link bank-nav-link" to="/payment-trends/credit/state-bank-of-india-credit-card">
                    SBI Credit Cards 
                  </Link>
                </li>
                <li className="list-item">
                  <Link className="nav-link bank-nav-link" to="/payment-trends/credit/kotak-mahindra-bank-credit-card">
                    Kotak Bank Credit Card 
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="container mt-7 m-container credit-debit-bg">
          <div className="row justify-content-between">
            <div className="col-lg-6 d-flex align-items-center order-lg-1">
              <div>
                <h1 className="fs-1 fw-bold">
                  <Link className="nav-link" to="/payment-trends/debit-cards">Debit Card Trends</Link>
                </h1>
                <div className="d-flex">
                  <h6>Stay on top of latest debit card issuance and spends trends</h6>
                  {/* <h6>Stay Updated with the Latest Debit Card Issuance and Spends Trends</h6> */}
                </div>
              </div>
            </div>
            <div className="col-lg-6 d-flex align-items-center justify-content-center text-center background-image order-lg-2">
              <Link className="nav-link" to="/payment-trends"><img src="asset/images/best-debit-cards-india-trends-2024.svg" alt="Chart showing the best debit cards usage in India in 2024" className="img-fluid" /></Link>
            </div>
          </div>
          <div className="row mt-5">
            <h4 className="title">Track Debit Card trends for your bank</h4>
            <div className="col-12 d-flex flex-column mt-4">
              <ul className="list bank-list">
                <li className="list-item bank-list-item">
                  <Link className="nav-link bank-nav-link" to="/payment-trends/debit/hdfc-bank-debit-card">
                    HDFC Bank Debit Card  
                  </Link>
                </li>
                <li className="list-item">
                  <Link className="nav-link bank-nav-link" to="/payment-trends/debit/icici-bank-debit-card">
                    ICICI Bank Debit Card 
                  </Link>
                </li>
                <li className="list-item">
                  <Link className="nav-link bank-nav-link" to="/payment-trends/debit/axis-bank-debit-card">
                    Axis Bank Debit Card  
                  </Link>
                </li>
                <li className="list-item">
                  <Link className="nav-link bank-nav-link" to="/payment-trends/debit/indusind-bank-debit-card">
                    IndusInd Debit Cards
                  </Link>
                </li>
                <li className="list-item">
                  <Link className="nav-link bank-nav-link" to="/payment-trends/debit/idfc-first-bank-debit-card">
                    IDFC First Bank Debit Card
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="container mt-7 m-container">
          <div className="row justify-content-center align-items-center text-bg-sec-dark rounded">
            <div className="col-12 mt-6 mb-5 text-center">
              <h1 className="info-text-2">Stay On Top of Payment Trends</h1>
              <div className="mt-3">
                <p>Get alerts on latest spend reports & insights</p>
              </div>
              <div className="d-flex justify-content-center mt-5">
                <form className="sub-form">
                  <div className="mr-1">
                    <input
                      type="email"
                      placeholder="Your Email"
                      className="form-control me-2"
                      value={email}
                      onChange={handleEmailChange}
                    />
                  </div>
                  <div>
                    <button type="button" className="btn btn-primary" onClick={handleSubscribe}>Subscribe</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>


      <Footer />
      <Modal show={showModal} onHide={handleCloseModal} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Edit Trends for the Month</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            {data.map(item => (
              <div key={item.id} className="card p-3 m-3">
                <Form.Group controlId={`name-${item.id}`}>
                  <Form.Label><b>Title</b></Form.Label>
                  <Form.Control
                    type="text"
                    value={item.name}
                    onChange={(e) => handleChange(item.id, 'name', e.target.value)}
                  />
                </Form.Group>
                { (item.id === 1 || item.id === 2) && (
                  <Row>
                    <Col md={6}>
                      <Form.Group controlId={`value-${item.id}`}>
                        <Form.Label><b>Value</b></Form.Label>
                        <Form.Control
                          type="text"
                          value={item.value}
                          onChange={(e) => handleChange(item.id, 'value', e.target.value)}
                        />
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group controlId={`change-${item.id}`}>
                        <Form.Label><b>Change</b></Form.Label>
                        <Form.Control
                          type="text"
                          value={item.change}
                          onChange={(e) => handleChange(item.id, 'change', e.target.value)}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                )}
              </div>
            ))}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleSave}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </div>


  );
};

export default Home;